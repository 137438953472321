export const dash = {
  /**
  * 登录
  */
  login: '/api/regional/regionaluser/loginByPassword',
  /**
   * 数据可视化
   */
  dashboardData: '/api/dashboard/dashboard',
  /**
   * 区域设备统计
   */
  devicesStaticByRegion: '/api/dev/dashboard/devicesStaticByRegion',

  /**
   *  最近三十天各楼宇警情排名
  */
  getLastMonthTowerEventRank: '/api/dashboard/getLastMonthTowerEventRank',
  /**
 * 设备列表
 */
  deviceList: '/api/dev/devonlinedevice/page',//设备列表
  /**
   * 后台监控列表
   */
  adminMonitorList: '/api/dev/monitordevice/page',//后台监控列表
  /**
   * 实时报警
   */
  alertsRealtime: '/api/dev/devonlinedevice/alertsRealtimePage',
  /**
   * 方舱列表
   */
  devSquarecabin: '/api/dev/squarecabin/page',
    /**
   * 方舱下设备列表
   */
    devonlinedevice: '/api/dev/devonlinedevice/page',
  /**
   * 一周设备异常趋势
   */
  deviceStatisticsWeeklyTrends: '/api/dev/devonlinedevice/deviceStatisticsWeeklyTrends'
}