import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // dashUser: localStorage.getItem('dashUser')?JSON.parse(localStorage.getItem('dashUser')):{},
    // dashUrl: localStorage.getItem('dashUrl')||'https://dash.api.fhj119.com',
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
